html, body {
    margin: 0;
    padding: 0;
}

* {
  	box-sizing: border-box;
  	-webkit-text-size-adjust: none;
}

body {
	font-family: $body-font-family;
	font-size: $body-font-size;
	color: $body-font-color;
	background: darken(#202222, 3%);
}

p {
	line-height: 1.5;
}

li {
	p {
		margin: 0;
	}
}

address {
	font-style: normal;
}

hr {
	margin-top: 0;
	margin-bottom: rem-calc(16);
	border-color: $border-color;
}

iframe {
	border: 0;
	width: 960px;
	height: 540px;
}

.unstyled {
    margin: 0;
    li {
        list-style-type: none;
    }
}