.post__title {
	margin-bottom: rem-calc(2);
	a {
		text-decoration: none;
	}
}

.post__date {
	color: #fff;
	// margin-bottom: rem-calc(16);
}

.post__tease {
	margin-bottom: rem-calc(30);

	.post__date {
		margin-bottom: rem-calc(8);
	}
}

.back-link {
	color: #fff;
	text-decoration: none;
	@include icon($chevron-left){
		color: $primary-color;
		font-size: rem-calc(11);
		margin-right: rem-calc(6);
		position: relative;
		top: 1px;
	}
}

.read-more {
	// color: #fff;
	text-decoration: underline;
	// @include icon($chevron-right, after){
	// 	color: $primary-color;
	// 	font-size: rem-calc(11);
	// 	margin-left: rem-calc(6);
	// 	position: relative;
	// 	top: 1px;
	// }
}