@import "foundation/functions";

// Don't include html classes to stop css bloat
$include-html-classes: false;

// Include these classes
$include-html-grid-classes: true;
$include-html-block-grid-classes: true;
$include-html-visibility-classes: true;
$include-html-type-classes: true;

// Colors
$body-font-color: #999;
$header-line-height: 1;
$primary-color: #a1d35f;
$link-color: #00AF00;
$anchor-font-color-hover: $primary-color;
$border-color: #444;

$row-width: rem-calc(1150);

// Fonts
$body-font-size: rem-calc(13);
$body-font-family: "Open Sans";
$brand-font-family: "Merriweather";
$small-font-size: 80%;

$h1-font-size: rem-calc(23);
$h2-font-size: rem-calc(18);
$h3-font-size: rem-calc(15);
$h4-font-size: rem-calc(13);
$paragraph-font-size: $body-font-size;

// Heading reduction for small screens (subtracted from default h1 sizes)
$h1-font-reduction: rem-calc(4);
$h2-font-reduction: rem-calc(2);
$h3-font-reduction: rem-calc(2);

// Media Queries
$small-range: (0em, 40em);
$medium-range: (40.063em, 63em);
$medium-down: "screen and (max-width:#{upper-bound($medium-range)})";
$large-range: (63.063em, 90em);
$xlarge-range: (90.063em, 120em);
$xxlarge-range: (120.063em, 99999999em);

