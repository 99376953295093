// Generated by grunt-webfont    
@font-face {
    font-family:"aucomvicons";
    src:url("../icons/icons.eot?c6627367ac4c40993602f844736bafef");
    src:url("../icons/icons.eot?#iefix") format("embedded-opentype"),
		url("../icons/icons.woff?c6627367ac4c40993602f844736bafef") format("woff"),
		url("../icons/icons.ttf?c6627367ac4c40993602f844736bafef") format("truetype"),
		url("../icons/icons.svg?c6627367ac4c40993602f844736bafef#icons") format("svg");
    font-weight:normal;
    font-style:normal;
}



    $chevron-left: "\f101" ;

    $chevron-right: "\f102" ;



@mixin icon-font-family($char) {
  font-family: "aucomvicons";
}

@mixin icon-font() {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@mixin icon-content($char) {
  content: $char;
}

@mixin icon($char) {
  @include icon-font-family($char);
  @include icon-font();

  &:before {
    @include icon-content($char);
  }
}