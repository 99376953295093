.main-navigation {
	text-align: left;
	clear: both;
	padding-top: rem-calc(13);
	padding-bottom: rem-calc(6);

	@include clearfix();

	@media screen and (min-width: 700px) {
		width: 54%;
		float: right;
		clear: none;
		margin-top: rem-calc(13);
	} 

	@media #{$large-up} {
		width: 100%;
		float: none;
		padding: 0;
		margin: 0;
	}

	.menu-item {
		width: 50%;
		float: left;

		@media #{$large-up} {
			width: 100%;
		}

		a {
			border-top: 1px solid $border-color;
			color: #888;
			font-family: $brand-font-family;
			letter-spacing: 0.04em;
			display: block;
			padding: rem-calc(8 12 8 14);
			border-left: 3px solid transparent;
			text-decoration: none;
			font-size: rem-calc(12.5);
			&:hover {
				color: #fff;
				border-left: 3px solid $primary-color;
			}
			@include icon($chevron-right, after){
				font-size: rem-calc(11);
				position: relative;
				top: 4px;
				float: right;
			}
		}
	}

	.current-menu-item,
	.current_page_parent {
		a {
			color: #fff;
			border-left: 3px solid $primary-color;
		}
	}
}