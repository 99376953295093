
.video__item {
    display: none;
}

.video__item li:first-child {
    display: block;
}

.video__title {
    margin-top: rem-calc(13);
}

.rp-player {
    overflow: hidden; /* collapse fix */
    width: 100%;
    font: normal 100%/1.25 Arial, Helvetica, sans-serif;
    margin-bottom: 25px;

    ::-webkit-scrollbar {
        width: 10px;
    }
    ::-webkit-scrollbar-track {
        background: #444444;
        -webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.1);
    }
    ::-webkit-scrollbar-thumb {
        background: #666666;
        -webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.2);
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #999999;
    }
    ::-webkit-scrollbar-thumb:active {
        background: #999999;
        -webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.3);
    }
}

.rp-player__container {
    float: none;
    width: auto;
}

.rp-player__video {
    position: relative;
    height: 0;
    padding-top: 0px; /* IE6 workaround*/
    padding-bottom: 56.25%; /* responsive 16/9 ratio */
    background: #000000;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.rp-player__playlist-container {
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; /* bounce effect on ios */
    float: right;
    width: 100%;
    height: 0;
    padding-top: 0px; /* IE6 workaround*/
    padding-bottom: 50%; /* responsive height ratio */
    background: #333333;
}

.rp-player__playlist {
    position: absolute;
    width: 100%; /* fixes horizontal scroll */
    list-style-type: none;
    margin: 0;
}

.rp-player__playlist li {
    overflow: hidden;
    float: left;
    width: 100%;
    border-top: 1px solid #444444;
    border-bottom: 1px solid #1d1d1d;
}

.rp-player__playlist li:hover {
    background: #3f3f3f;
}

.rp_currentVideo,
.rp-player__playlist .rp_currentVideo:hover {
    background: #4a4a4a;
}

.rp-player__playlist li a {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    padding: 2.5%;
    text-shadow: 0 1px 0 #000000;
    text-decoration: none;
}

.rp_thumbnail {
    position: relative;
    display: inline-block;
    overflow-y: hidden; /* -y fix for android */
    float: left;
    width: 40px;
    height: 22px; /* clips letterboxing */
    margin-right: 2.5%;
    border: 1px solid #222222;
    overflow: hidden;
}

.rp-player__playlist li:hover .rp_thumbnail {
    border: 1px solid #666666;
}

.rp_currentVideo .rp_thumbnail,
.rp-player__playlist .rp_currentVideo:hover .rp_thumbnail {
    border: 1px solid #999999;
}

.rp_thumbnail img {
    position: absolute;
    top: -4px; /* clips letterboxing */
    width: 40px;
}

.rp_title {
    overflow: hidden;
    color: #ffffff;
    font-size: 12px; /* fallback */
    font-size: 0.75rem; /* 13/16 */
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rp_author {
    overflow: hidden;
    color: #6e6e6e;
    font-size: 10px; /* fallback */
    font-size: 0.625rem; /* 11/16 */
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rp-player__playlist li:hover .rp_author {
    color: #aaaaaa;
}

.rp_currentVideo .rp_author,
.rp-player__playlist .rp_currentVideo:hover .rp_author {
    color: #ffffff;
}

.rp-player__playlist p {
    margin: 0;
}


@media screen and (min-width: 320px) {
    .rp_thumbnail {width: 60px; height: 33px;}
    .rp_thumbnail img {top: -6px; width: 60px;}
    .rp_author {padding-top: 2px;}
}

@media screen and (min-width: 480px) {
    .rp_thumbnail {width: 80px; height: 45px;}
    .rp_thumbnail img {top: -8px; width: 80px;}
    .rp_title {font-size: 15px; font-size: 0.9375rem;}
    .rp_author {font-size: 12px; font-size: 0.75rem; padding-top: 6px;}
}

@media screen and (min-width: 720px) {
    .rp-player__video-container {float: left; width: 70%;}
    .rp-player__playlist-container {width: 30%; padding-bottom: 39.35%;}
    .rp_thumbnail {width: 60px; height: 33px;}
    .rp_thumbnail img {top: -6px; width: 60px;}
    .rp_title {font-size: 13px; font-size: 0.8125rem;}
    .rp_author {font-size: 11px; font-size: 0.6875rem; padding-top: 2px;}
}

@media screen and (min-width: 960px) {
    .rp_thumbnail {width: 80px; height: 45px;}
    .rp_thumbnail img {top: -8px; width: 80px;}
    .rp_author {padding-top: 4px;}
}

/* iphone landscape fix */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape), (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) {
    .rp-player__video-container {float: left; width: 70%;}
    .rp-player__playlist-container {width: 30%; padding-bottom: 39.35%;}
    .rp_thumbnail {width: 40px; height: 22px;}
    .rp_thumbnail img {top: -4px; width: 40px;}
    .rp_title {font-size: 12px; font-size: 0.75rem;}
    .rp_author {font-size: 10px; font-size: 0.625rem;}
}