.l-fluid-image {
	width: 100%;
	height: auto;
}

.l-section {
	margin-top: rem-calc(20);
	margin-bottom: rem-calc(20);
}

.l-center-align {
	text-align: center;
}

.row.full {
	max-width: none;
}

.content {
	padding: rem-calc(18);
	@media #{$large-up} {
		padding: rem-calc(50 50 50 45);
		margin-left: rem-calc(270);
	}
	@media #{$medium-only} {
		padding: rem-calc(35);
	}
}

.sidebar {
	background: #202222;
	padding-top: rem-calc(6);
	@media #{$medium-only} {
		padding-bottom: rem-calc(16);
	}
	@media #{$large-up} {
		height: 100vh;
		text-align: center;
		padding-top: 0;
		width: rem-calc(270);
		position: fixed;
	}
}

.logo {
	width: rem-calc(130);
	img {
		width: 100%;
		height: auto;
	}
	margin-top: rem-calc(8);
	float: left;
	display: block;
	@media #{$large-up} {
		float: none;
		margin: rem-calc(16) auto 0;
	}
}

.site-footer {
	@media #{$large-up} {
		position: fixed;
		bottom: rem-calc(16);
		left: rem-calc(16);
		width: 100%;
		text-align: left;
	}
}