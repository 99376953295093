h1, h2, h3 {
	margin-top: 0;
	letter-spacing: 0.03em;
	span {
		display: block;
		color: $primary-color;
		font-size: rem-calc(15);
		margin-top: rem-calc(2);

		@media #{$medium-up} {
			font-size: rem-calc(16.5);
		}
	}
}

h1 {
	color: #fff;
	margin-bottom: rem-calc(18);
	padding-bottom: rem-calc(6);
	border-bottom: 1px solid #888;
	font-family: $brand-font-family;
	
	@media #{$medium-up} {
		margin-bottom: rem-calc(32);
	}
}

h2 {
	color: $primary-color;
}

h3 {
	color: $primary-color;
	font-family: $body-font-family;
	margin-top: rem-calc(10);
}

h4 {
	font-family: $body-font-family;
}

a {
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

.site-title {
	color: #fff;
	line-height: 1.1;
	margin: rem-calc(83 0 18 18);
	float: left;

	@media #{$medium-up} {
		margin-top: rem-calc(72);
	}

	@media #{$large-up} {
		float: none;
		font-size: rem-calc(20);
		margin: rem-calc(14 0 21 0);
	}
}